exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-acquia-engage-2020-js": () => import("./../../../src/pages/acquia-engage-2020.js" /* webpackChunkName: "component---src-pages-acquia-engage-2020-js" */),
  "component---src-pages-boston-js": () => import("./../../../src/pages/boston.js" /* webpackChunkName: "component---src-pages-boston-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-drupalcon-2021-event-js": () => import("./../../../src/pages/drupalcon-2021-event.js" /* webpackChunkName: "component---src-pages-drupalcon-2021-event-js" */),
  "component---src-pages-form-test-js": () => import("./../../../src/pages/form-test.js" /* webpackChunkName: "component---src-pages-form-test-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-shopify-plus-food-and-beverage-js": () => import("./../../../src/pages/shopify-plus-food-and-beverage.js" /* webpackChunkName: "component---src-pages-shopify-plus-food-and-beverage-js" */),
  "component---src-templates-insights-js": () => import("./../../../src/templates/insights.js" /* webpackChunkName: "component---src-templates-insights-js" */),
  "component---src-templates-landings-js": () => import("./../../../src/templates/landings.js" /* webpackChunkName: "component---src-templates-landings-js" */),
  "component---src-templates-legacy-insights-js": () => import("./../../../src/templates/legacyInsights.js" /* webpackChunkName: "component---src-templates-legacy-insights-js" */),
  "component---src-templates-studies-js": () => import("./../../../src/templates/studies.js" /* webpackChunkName: "component---src-templates-studies-js" */)
}

